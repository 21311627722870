<template>
  <div class="flex h-full justify-center items-center">
    <div class="container w-full mx-auto max-w-3xl">
      <div class="bg-white mt-5 p-10 mb-4" data-testid="page-error">
        <div class="text-2xl uppercase font-black font-oswald">
          {{ title }}
        </div>

        <div class="text-2lg mt-3">
          {{ description }}
        </div>

        <div v-if="showButton" class="border-t border-medium-grey mt-14 pt-5">
          <Button
            classList="text-lg"
            :text="buttonText"
            theme="full"
            @click-action="methods.goHome"
          />
        </div>

        <div
          v-if="apiConnectionError"
          class="border-t border-medium-grey mt-14 pt-5"
        >
          {{ $t(`error.api-connection-error.subline`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue';

import { useRoute } from 'vue-router';
import router from '@/router';

import Button from '@/components/general/Button';
import { RouteName } from '@/enums/Routes';
import { useI18n } from 'vue-i18n';
import { getFeatureFlag, FeatureFlag } from '@/services/config';

export default {
  components: {
    Button,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const goHome = () => {
      router.push({ name: RouteName.ROOT });
    };

    const buttonText = computed(() =>
      route.query.errorSource === 'auth0'
        ? t('general.login')
        : t('general.home')
    );

    const apiConnectionError = inject('apiConnectionError');
    const maintenanceMode = getFeatureFlag(FeatureFlag.MAINTENANCE_MODE)
      .enabled;

    let showButton = true;
    let title = '';
    let description = '';

    if (apiConnectionError) {
      title = t(`error.api-connection-error.title`);
      description = t(`error.api-connection-error.description`);
      showButton = false;
    } else if (route.query.errorDescription) {
      title = t(`error.default.title`);
      description =
        route.query.errorDescription || t(`error.default.description`);
    } else if (route.params.errorCode) {
      title = t(`error.${route.params.errorCode}.title`);
      description = t(`error.${route.params.errorCode}.description`);
    } else if (maintenanceMode) {
      title = t(`error.maintenance-mode.title`);
      description = t(`error.maintenance-mode.description`);
      showButton = false;
    } else {
      title = t(`error.not-found.title`);
      description = t(`error.not-found.description`);
    }

    return {
      apiConnectionError,
      buttonText,
      description,
      showButton,
      title,
      methods: {
        goHome,
      },
    };
  },
};
</script>
